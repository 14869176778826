import jsQR from "jsqr"

export default class QrScanner {
    constructor(canvasEl, size=300) {
        this.canvasEl = canvasEl
        this.canvas = canvasEl.getContext('2d')
        this.videoEl = document.createElement('video')
        this.wLocation = window.location
        this.loading = false
        this._size = {
            w: size,
            h: size
        }
    }

    set size(size) {
        this._size = size
    }

    run() {
        const self = this
        navigator.mediaDevices.getUserMedia({ video: {
            facingMode: "environment"
        }}).then(function(stream) {
            self.videoEl.srcObject = stream
            self.videoEl.setAttribute("playsinline", true)
            self.videoEl.play()
            requestAnimationFrame(() => {
              self.tick()
            })
        })
    }

    tick() {
        if (this.videoEl.readyState === this.videoEl.HAVE_ENOUGH_DATA) {
            this.canvasEl.hidden = false
            const videoPr = this.videoEl.videoHeight / this.videoEl.videoWidth
            this.canvasEl.width = this._size.w
            this.canvasEl.height = this.canvasEl.width * videoPr
            this.canvas.drawImage(this.videoEl, 0, 0, this.canvasEl.width, this.canvasEl.height)
            const imageData = this.canvas.getImageData(0, 0, this.canvasEl.width, this.canvasEl.height)
            const code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: "dontInvert",
            })
            if (code && this.testCode(code)) {
                this.drawSquare(code.location, "#FF3B58")
                if (!this.loading) {
                    window.location.href = code.data
                    this.loading = true
                }
            }
        }
        requestAnimationFrame(() => {
            this.tick()
        })
    }

    testCode(code) {
        const codeData = code.data
        const cul = this.wLocation
        const locationRegex = new RegExp(`^${cul.protocol}//${cul.host}/t/[a-zA-Z0-9-_]+/[?](pid=[0-9A-Za-z]{6}|sid=[0-9a-zA-Z-]{36})$`)
        return locationRegex.test(codeData)
    }

    drawSquare({topLeftCorner, topRightCorner, bottomRightCorner, bottomLeftCorner}, color) {
        const canvas = this.canvas
        const corners = [topLeftCorner, topRightCorner, bottomRightCorner, bottomLeftCorner, topLeftCorner]
        for (let i = 0; i < 4; i++) {
            canvas.beginPath()
            const start = corners[i]
            const end = corners[i+1]
            canvas.moveTo(start.x, start.y)
            canvas.lineTo(end.x, end.y)
            canvas.lineWidth = 4
            canvas.strokeStyle = color
            canvas.stroke()
        }
    }
}