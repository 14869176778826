<template>
    <div class="ma-auto" :style="`width: ${size}px`">
        <canvas id="canvas"></canvas>
    </div>
</template>

<script>
    import QrScanner from "./scanner"
    export default {
        name: "Scanner",
        props: ['size'],
        data() {
            return {
                scanner: null,
                scanning: false
            }
        },
        mounted() {
            const canvasEl = document.getElementById("canvas")
            this.scanner = new QrScanner(canvasEl, this.size)
            this.scanner.run()
        }
    }
</script>

<style scoped>

</style>
