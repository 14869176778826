<template>
    <v-app>
        <v-app-bar app>
            <a href="https://myphoneyaku.com">
                <v-img max-height="50px" alt="MyPhone訳ロゴ" src="/static/assets/logo_sm.png" contain position="left center"></v-img>
            </a>
        </v-app-bar>
        <v-content>
            <v-container fluid>
                <v-alert type="error" v-for="(alert, index) in alerts" :key="index">
                {{ alert }}
                </v-alert>
                <scanner :size="halfWindowSize"></scanner>
                <v-row justify="center">
                    <h3>Qrcodeをスキャンしてください</h3>
                </v-row>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    import Scanner from "./Scanner.vue"
    export default {
        name: "Home",
        components: {
            Scanner
        },
        data() {
            return {
                alerts: this.$root.$data.alerts
            }
        },
        computed: {
            halfWindowSize() {
                const compareSize = document.body.offsetWidth * 3 / 4
                return compareSize > 300 ? (compareSize > 800 ? 800 : compareSize) : 300
            },
            theme() {
              return (this.$vuetify.theme.dark) ? 'dark' : 'light'
            }
        }
    }
</script>

<style>
    html {
        overflow-y: auto;
    }
</style>
