import Vue from 'vue'
import i18n from "../_common/plugins/i18n"
import { buildVuetify } from "../_common/plugins/vuetify"
import '@mdi/font/css/materialdesignicons.css'
import Home from "./Home.vue"
const vuetify = buildVuetify({
    theme: {
        themes: {
            light: {
                backgroundLeft: "#8ed5b5",
                backgroundRight: "#fffebb",
                button: "#fa6c84",
                activeButton: "#8ed5b5",
                primary: "#fa6c84",
            }
        }
    }
})


new Vue({
    render: h => h(Home),
    i18n,
    vuetify,
    data() {
        return {
          alerts: window.requestContext.alerts
        }
    }
}).$mount('#home')
